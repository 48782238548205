import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import { Container, Row, Column } from "common/core/responsive";
import {
  BackButton,
  StartOverButton,
  useNewSetupPageAnalytics,
  TrackNewSetupPageAnalyticsContext,
  Step1Header,
  PageTitle,
} from "common/mortgage/transactions/setup";
import ClosingTypeCard, {
  type ClosingType,
  getConfigIdFromClosingType,
  getMutationParamFromClosingType,
  useClosingType,
} from "common/mortgage/transactions/setup/closing_type";
import PropertyAddressCard, {
  usePropertyAddressInput,
} from "common/mortgage/transactions/setup/property_address";
import SaveAndCreateTransaction, { Blurb } from "common/mortgage/transactions/setup/save";
import { useCollaboratorSearchingType } from "common/mortgage/transactions/setup/collaborator";
import TitleUnderwriterCard, {
  useTitleUnderWriterOption,
} from "common/mortgage/transactions/setup/title_underwriter";
import { useActiveOrganization } from "common/account/active_organization";
import { useMutation } from "util/graphql";
import { locationRecordable } from "common/mortgage/transactions/utils";
import { b } from "util/html";
import { transactionEditRoute } from "util/routes";
import { LOAN_MOD_BORROWER, NON_ONBOARDED_TITLE_ENABLED } from "constants/feature_gates";
import { PAPER_RECORDING_BLOG_POST } from "constants/marketing";
import { MortgageTransactionType, RecordingTypes } from "graphql_globals";
import { useOrgFlag } from "common/org_flag_gating";
import LoadingIndicator from "common/core/loading_indicator";
import { useFeatureFlag } from "common/feature_gating";
import { isConditional } from "util/requirements";
import { getRequirements } from "common/mortgage/transactions/requirements_service/service";
import { MORTGAGE_TRANSACTION_REQUIREMENTS } from "constants/transaction";

import Styles from "./index.module.scss";
import CreateOrganizationTransactionV2 from "./create_organization_transaction.mutation.graphql";

type InteractionCollection = {
  closingTypeInteraction: ReturnType<typeof useClosingType>;
  propertyAddressInteraction: ReturnType<typeof usePropertyAddressInput>;
  collaboratorSearchingTypeInteraction: ReturnType<typeof useCollaboratorSearchingType>;
  titleUnderwriterOptionInteraction: ReturnType<typeof useTitleUnderWriterOption>;
};

function fulfillsTitleUnderwriterRequirement(
  titleUnderwriterOptionInteraction: InteractionCollection["titleUnderwriterOptionInteraction"],
) {
  const { titleUnderwriter, option } = titleUnderwriterOptionInteraction;
  return titleUnderwriter || option === "no";
}

function calculateEcloseOption({
  closingTypeInteraction,
  propertyAddressInteraction,
  collaboratorSearchingTypeInteraction,
  titleUnderwriterOptionInteraction,
}: InteractionCollection) {
  const { closingType } = closingTypeInteraction;
  const { propertyAddress, selectedRecordingLocation, availableRecordingLocations, formErrors } =
    propertyAddressInteraction;
  const { eligibleTitleUnderwriters, loading: collaboratorLoading } =
    collaboratorSearchingTypeInteraction;
  const { option } = titleUnderwriterOptionInteraction;
  const requiresUnderwriter = option === "yes";
  const generalEclose =
    closingType === MortgageTransactionType.heloc ||
    closingType === MortgageTransactionType.loan_mod_borrower;

  const emptyDisabledState = {
    type: "disabled" as const,
    buttonHint: (
      <FormattedMessage
        id="f1a99d89-ec15-4806-a8ce-41c2bbc4ad72"
        defaultMessage="eClosing eligibility must be verified in order to continue with an eClosing."
      />
    ),
  };

  if (!closingType || formErrors) {
    return emptyDisabledState;
  }

  if (propertyAddress && availableRecordingLocations.length === 0) {
    return {
      type: "disabled" as const,
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="2a58d5df-797c-4f17-bfdc-019125e4a822"
            defaultMessage="<b>We were unable to find a recording location for this property.</b> This closing cannot be completed online."
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  if (selectedRecordingLocation && !selectedRecordingLocation.usState.ronSupported) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="47829ef5-99b5-4575-8854-38630a016bae"
            defaultMessage="<b>Proof does not currently support full eClosings for property in this state.</b>"
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  if (
    selectedRecordingLocation &&
    !selectedRecordingLocation.usState.supportedTransactionTypes?.includes(closingType)
  ) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="47829ef5-99b5-4575-8854-38630a016bae"
            defaultMessage="<b>This state does not allow the selected closing type to be conducted using RON.</b>"
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  if (
    selectedRecordingLocation &&
    !selectedRecordingLocation.erecordingSupported &&
    selectedRecordingLocation.recordingType === RecordingTypes.NONE
  ) {
    return {
      type: "disabled" as const,
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="5b24f300-21ad-4886-87c9-4389ca6dca4d"
            defaultMessage="<b>This property's recording location (county) does not eRecord.</b> This closing cannot be completed online."
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  if (!eligibleTitleUnderwriters.length && !collaboratorLoading && requiresUnderwriter) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="280e3372-825d-4dc4-bac5-4ee66e10ea91"
            defaultMessage="<b>We were unable to find an underwriter</b> that works with this business, in this state, and whose notary requirements are serviceable on Proof."
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  if (
    eligibleTitleUnderwriters.length &&
    !eligibleTitleUnderwriters.some((underwriter) => underwriter.compatibleWithAvailableNotaries) &&
    requiresUnderwriter
  ) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="f6bb08b1-65b8-4b8f-b741-f55c1617a673"
            defaultMessage="<b>There are no eligible notaries for this closing.</b> This property is eligible for an eClose, but no notaries can service it."
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  const titleUnderwriterRequirements = fulfillsTitleUnderwriterRequirement(
    titleUnderwriterOptionInteraction,
  );
  if (
    selectedRecordingLocation?.recordingType === RecordingTypes.PAPER &&
    titleUnderwriterRequirements
  ) {
    return {
      blurb: (
        <Blurb type="info">
          <FormattedMessage
            id="3f2d9d20-0a5f-481a-912b-69a026356fe4"
            defaultMessage="<b>This property is eligible for an eClosing, but you need to paper out due to eRecording availability.</b> <link>Learn more</link> about paper out laws."
            values={{
              b,
              link: (text) => <Link href={PAPER_RECORDING_BLOG_POST}>{text}</Link>,
            }}
          />
        </Blurb>
      ),
    };
  }

  if (
    selectedRecordingLocation?.recordingType === RecordingTypes.ELECTRONIC &&
    (titleUnderwriterRequirements || generalEclose)
  ) {
    return {
      blurb: (
        <Blurb type="success">
          <FormattedMessage
            id="6961d835-3a7d-4c59-8a64-c78333394287"
            defaultMessage="<b>This property is eligible for an eClosing.</b> Complete this closing fully on the Proof platform, including the notary meeting."
            values={{
              b,
            }}
          />
        </Blurb>
      ),
    };
  }

  return emptyDisabledState;
}

function useSetupTransactionExecution(props: InteractionCollection & { organizationId: string }) {
  const {
    organizationId,
    closingTypeInteraction: { closingType },
    propertyAddressInteraction: {
      propertyAddress,
      fetching: propertyAddressLoading,
      selectedRecordingLocation,
      formErrors,
    },
    collaboratorSearchingTypeInteraction: { loading: collaboratorLoading },
    titleUnderwriterOptionInteraction,
  } = props;
  const { titleUnderwriter } = titleUnderwriterOptionInteraction;
  const { track } = useNewSetupPageAnalytics();
  const [creatingTransaction, setCreatingTransaction] = useState(false);
  const createTransaction = useMutation(CreateOrganizationTransactionV2);
  const navigate = useNavigate();
  const canCreateBaseEclose =
    (closingType === MortgageTransactionType.heloc ||
      closingType === MortgageTransactionType.loan_mod_borrower) &&
    locationRecordable(selectedRecordingLocation) &&
    selectedRecordingLocation?.usState.supportedTransactionTypes?.includes(closingType);
  const formLoading = propertyAddressLoading || collaboratorLoading;
  const canCreateTransaction =
    !formLoading &&
    !formErrors &&
    (fulfillsTitleUnderwriterRequirement(titleUnderwriterOptionInteraction) || canCreateBaseEclose);

  const createNewTransaction = async () => {
    setCreatingTransaction(true);
    track("continue-button", {
      orgType: "title",
      type: "eclose",
      recordingType: selectedRecordingLocation!.recordingType,
    });
    const response = await createTransaction({
      variables: {
        input: {
          organizationId,
          transaction: {
            configId: getConfigIdFromClosingType(closingType!, false, true),
            transactionType: getMutationParamFromClosingType(closingType!, false),
            streetAddress: propertyAddress,
            titleUnderwriterOrgId: titleUnderwriter?.id,
            recordingLocationId: selectedRecordingLocation?.id,
            titleAgencyOrgId: organizationId,
            isMortgage: true,
          },
          transactionMemberships: {
            titleAgencyId: organizationId,
          },
          customers: [{}],
        },
      },
    });

    const transaction = response.data!.createOrganizationTransactionV2!.organizationTransaction;
    navigate(transactionEditRoute(transaction), { replace: true });
  };

  const closingOptions = {
    eclose: {
      loading: creatingTransaction,
      onContinue: canCreateTransaction ? createNewTransaction : null,
      ...calculateEcloseOption(props),
    },
  };

  return {
    closingOptions,
  };
}

function showUnderwriterForType(closingType: ClosingType | null) {
  return (
    closingType &&
    isConditional(
      getRequirements(closingType, "title")[MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER],
    )
  );
}

export default function SetupTransactionV2() {
  const [titleAgencyId] = useActiveOrganization();
  const closingTypeInteraction = useClosingType();
  const { closingType } = closingTypeInteraction;
  const { orgFlagData, orgFlagLoading } = useOrgFlag(titleAgencyId as string);
  const nononboardedEnabled = Boolean(orgFlagData[NON_ONBOARDED_TITLE_ENABLED]);
  const propertyAddressInteraction = usePropertyAddressInput(nononboardedEnabled);
  const { selectedRecordingLocation, formErrors: propertyAddressFormErrors } =
    propertyAddressInteraction;
  const collaboratorSearchingTypeInteraction = useCollaboratorSearchingType({
    titleAgencyId,
    selectedRecordingLocation,
  });
  const { loading: collaboratorLoading, eligibleTitleUnderwriters } =
    collaboratorSearchingTypeInteraction;
  const titleUnderwriterOptionInteraction = useTitleUnderWriterOption({
    titleAgencyId,
  });

  const { closingOptions } = useSetupTransactionExecution({
    organizationId: titleAgencyId!,
    closingTypeInteraction,
    propertyAddressInteraction,
    collaboratorSearchingTypeInteraction,
    titleUnderwriterOptionInteraction,
  });

  const showPropertyAddressCard = Boolean(closingType);
  const showUnderwriterCard =
    showPropertyAddressCard &&
    !propertyAddressFormErrors &&
    !collaboratorLoading &&
    showUnderwriterForType(closingType) &&
    locationRecordable(selectedRecordingLocation);
  locationRecordable(selectedRecordingLocation);

  useEffect(() => {
    collaboratorSearchingTypeInteraction.clear();
    titleUnderwriterOptionInteraction.clear();
  }, [selectedRecordingLocation]);

  useEffect(() => {
    titleUnderwriterOptionInteraction.clear();
  }, [closingType]);
  const compatibleUnderwriters = useMemo(() => {
    return eligibleTitleUnderwriters.filter(
      ({ compatibleWithAvailableNotaries }) => compatibleWithAvailableNotaries,
    );
  }, [eligibleTitleUnderwriters]);

  const handleClearForm = () => {
    closingTypeInteraction.clear();
    propertyAddressInteraction.clear();
    collaboratorSearchingTypeInteraction.clear();
    titleUnderwriterOptionInteraction.clear();
  };

  const deprecatedClosingTypes: ClosingType[] = [
    MortgageTransactionType.purchase_buyer_cash,
    MortgageTransactionType.heloc,
    MortgageTransactionType.purchase_seller,
  ];
  // Should be alphabetized
  const closingTypes: ClosingType[] = [
    MortgageTransactionType.purchase_buyer_cash,
    MortgageTransactionType.heloc,
    MortgageTransactionType.loan_mod_borrower,
    MortgageTransactionType.purchase_seller,
  ];
  const allowedClosingTypes: ClosingType[] = useFeatureFlag(LOAN_MOD_BORROWER)
    ? closingTypes
    : deprecatedClosingTypes;

  if (orgFlagLoading) {
    return <LoadingIndicator />;
  }

  return (
    <TrackNewSetupPageAnalyticsContext orgType="title">
      <Container>
        <Row className={Styles.header}>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row className={Styles.header}>
          <Column>
            <PageTitle />
          </Column>
        </Row>
        <Row>
          <Column xs={12} lg={7} className={Styles.column}>
            <Step1Header />
            <ClosingTypeCard
              allowedClosingTypes={allowedClosingTypes}
              interaction={closingTypeInteraction}
            />
            {showPropertyAddressCard && (
              <PropertyAddressCard
                interaction={propertyAddressInteraction}
                forceLoading={collaboratorLoading}
              />
            )}
            {showUnderwriterCard && (
              <TitleUnderwriterCard
                interaction={titleUnderwriterOptionInteraction}
                eligibleTitleUnderwriters={compatibleUnderwriters}
              />
            )}
          </Column>
          <Column xs={12} lg={5} xl={4} push={{ xl: 1 }} className={Styles.column}>
            <div>
              <SaveAndCreateTransaction closingOptions={closingOptions} />
              {closingType && <StartOverButton onClick={handleClearForm} />}
            </div>
          </Column>
        </Row>
      </Container>
    </TrackNewSetupPageAnalyticsContext>
  );
}
