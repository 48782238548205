import "./index.scss";

import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { useNavigate } from "react-router-dom";

import { getFormValues, composeValidators } from "util/form";
import compose from "util/compose";
import AppFrame from "common/app_frame";
import { TransactionCreationPageContainer } from "common/transaction_creation";
import { TransactionCreationPageSection } from "common/transaction_creation/section";
import { TransactionCreationPageFooter } from "common/transaction_creation/footer";
import LoadingIndicator from "common/core/loading_indicator";
import Button from "common/core/button";
import { PageFrameSmallWithPadding } from "common/page_frame";
import AgencyDetailsSubForm, {
  validationRules as agencyDetailsValidationRules,
} from "title_portal/organization/setup/sub_forms/agency_details";
import UserDetailsSubForm, {
  validationRules as userDetailsValidationRules,
} from "title_portal/organization/setup/sub_forms/user_details";
import {
  DeprecatedAddressSubForm,
  validationRules as addressValidationRules,
} from "common/form/sub_forms/address";
import OperatingStatesSubForm, {
  validationRules as operatingStatesValidationRules,
} from "title_portal/organization/setup/sub_forms/operating_states";
import TitleUnderwritersSubForm, {
  validationRules as titleUnderwritersValidationRules,
} from "title_portal/organization/setup/sub_forms/title_underwriters";
import asyncValidateEmail from "validators/email_async";
import { useQuery, useMutation } from "util/graphql";
import { scrollOnSubmitFail } from "util/scroll";
import { useActiveOrganization } from "common/account/active_organization";
import { TRANSACTION_PATH } from "util/routes";

import UpdateTitleAgencyForTitleSetupMutation from "./update_title_agency_mutation.graphql";
import UpdateUsStatesForTitleSetupMutation from "./update_us_states_mutation.graphql";
import UpdateTitleAgencyUnderwritersForTitleSetupMutation from "./update_title_agency_underwriters_mutation.graphql";
import GetTitleOrgForSetupQuery, {
  type GetTitleOrgForSetup_viewer as Viewer,
  type GetTitleOrgForSetup_organization_Organization as Organization,
} from "./index_query.graphql";

type FormValues = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
  phone: string;
  altaId: string;
  line1: string;
  line2: string;
  postal: string;
  country: string;
  city: string;
  state: string;
  states: { label: string; value: string }[];
  titleUnderwriters: { label: string; value: string }[];
};
type Props = {
  organization: Organization;
  viewer: Viewer;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props;

function validate(values: FormValues, props: Record<string, unknown>) {
  return composeValidators(
    userDetailsValidationRules(),
    addressValidationRules(values, props),
    agencyDetailsValidationRules(),
    operatingStatesValidationRules(),
    titleUnderwritersValidationRules(),
  )(values);
}

function useSaveHandler(organizationId: string) {
  const navigate = useNavigate();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const updateTitleAgencyMutateFn = useMutation(UpdateTitleAgencyForTitleSetupMutation);
  const updateUsStatesMutateFn = useMutation(UpdateUsStatesForTitleSetupMutation);
  const updateTitleAgencyTitleUnderwritersMutateFn = useMutation(
    UpdateTitleAgencyUnderwritersForTitleSetupMutation,
  );

  const callback = (formValues: FormValues) => {
    setFormSubmitting(true);
    return Promise.all([
      updateTitleAgencyMutateFn({
        variables: {
          input: {
            organizationId,
            organizationName: formValues.organizationName,
            phone: formValues.phone,
            altaId: formValues.altaId,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            address: {
              line1: formValues.line1,
              line2: formValues.line2,
              postal: formValues.postal,
              city: formValues.city,
              state: formValues.state,
              country: formValues.country,
            },
          },
        },
      }),
      formValues.states.length
        ? updateUsStatesMutateFn({
            variables: {
              input: {
                organizationId,
                usStateIds: formValues.states.map((s) => s.value),
              },
            },
          })
        : Promise.resolve(null),
      formValues.titleUnderwriters.length
        ? updateTitleAgencyTitleUnderwritersMutateFn({
            variables: {
              input: {
                organizationId,

                titleUnderwriterIds: formValues.titleUnderwriters.map((u) => u.value),
              },
            },
          })
        : Promise.resolve(null),
    ])
      .then(() => {
        navigate(TRANSACTION_PATH);
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  return { callback, formSubmitting };
}

function OrganizationSetupLoaded(props: InnerProps) {
  const { form, handleSubmit, organization, viewer } = props;
  const navigate = useNavigate();
  const user = viewer.user!;

  useEffect(() => {
    if (organization.isSetup.complete) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const { publicTitleAgency, address, usStates, name } = organization;
    props.initialize({
      userId: user.id,
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.pendingEmail || "",
      organizationName: !name || name === user.pendingEmail ? "" : name,
      phone: publicTitleAgency?.phone || "",
      altaId: publicTitleAgency?.altaId || "",
      line1: address.line1,
      line2: address.line2,
      postal: address.postal,
      country: address.country || "US",
      city: address.city,
      state: address.state,
      states: usStates!.map((s) => ({ label: s!.name, value: s!.id })),
      titleUnderwriters: publicTitleAgency!.titleUnderwriters.map((u) => ({
        label: u.name!,
        value: u.id,
      })),
    });
  }, []);

  const saveHandler = useSaveHandler(organization.id);

  return (
    <div className="TitleAgencySetup">
      <AppFrame staticPage>
        <PageFrameSmallWithPadding>
          <TransactionCreationPageContainer>
            <TransactionCreationPageSection
              title={
                <FormattedMessage
                  id="f18a6432-9159-4a57-ba8b-c4ff10a47a5e"
                  defaultMessage="Account details"
                />
              }
            >
              <form className="TitleAgencySetup--Form">
                <div className="TitleAgencySetup--Form--UserDetailsSubForm">
                  <UserDetailsSubForm formName={form} />
                </div>

                <div className="TitleAgencySetup--Form--UserDetailsSubForm">
                  <AgencyDetailsSubForm formName={form} />
                </div>

                <div className="TitleAgencySetup--Form--AddressSubForm">
                  <div className="TitleAgencySetup--Form--AddressSubForm--header">
                    <FormattedMessage
                      id="0f6af521-02ce-4142-b4fe-c8a26d2b7af0"
                      defaultMessage="Agency Address"
                    />
                  </div>
                  <DeprecatedAddressSubForm
                    formName={form}
                    useStyledInputs
                    displayRequiredAsterisk
                  />
                </div>
              </form>
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              title={
                <FormattedMessage
                  id="19595cca-9b5b-4538-b76a-a9355025306a"
                  defaultMessage="Operational details"
                />
              }
              subtitle={
                <FormattedMessage
                  id="231cc463-59fa-45b5-a155-d4119ef69922"
                  defaultMessage="This information used to match you with lenders and signers looking to close on a home."
                />
              }
            >
              <div className="TitleAgencySetup--Form--OperatingStatesSubForm">
                <OperatingStatesSubForm
                  formName={form}
                  options={viewer.usStates!.map((usState) => ({
                    label: usState!.name,
                    value: usState!.id,
                  }))}
                />
              </div>

              <div className="TitleAgencySetup--Form--TitleUnderwritersSubForm">
                <TitleUnderwritersSubForm
                  formName={form}
                  options={viewer.publicTitleUnderwriters.map((underwriter) => ({
                    label: underwriter.name,
                    value: underwriter.id,
                  }))}
                />
              </div>
            </TransactionCreationPageSection>
          </TransactionCreationPageContainer>
        </PageFrameSmallWithPadding>
      </AppFrame>
      <TransactionCreationPageFooter>
        <div className="TitleAgencySetup--FooterActions">
          <div className="TitleAgencySetup--FooterActions--text">
            <FormattedMessage
              id="98c4493d-c9af-4f52-8cfd-7bcbdd8dc31a"
              defaultMessage="Join our network of title agencies to manage eClosings!"
            />
          </div>
          <Button
            className="TitleAgencySetup--FooterActions--button"
            buttonSize="large"
            buttonColor="action"
            variant="primary"
            onClick={handleSubmit(saveHandler.callback)}
            automationId="get-started-button"
            disabled={saveHandler.formSubmitting}
          >
            <FormattedMessage
              id="f55efac0-8416-47ca-b14c-69ab39e22345"
              defaultMessage="Get started"
            />
          </Button>
        </div>
      </TransactionCreationPageFooter>
    </div>
  );
}

const OrganizationSetupLoadedWithForm = compose(
  reduxForm({
    form: "TitleAgencySetupForm",
    validate,
    // Validate email for typos
    asyncValidate: asyncValidateEmail,
    asyncBlurFields: ["email"],
    asyncChangeFields: ["email"],
    onSubmitFail: scrollOnSubmitFail,
  }),
  getFormValues("TitleAgencySetupForm"),
)(OrganizationSetupLoaded);

function OrganizationSetup() {
  const [activeOrgId] = useActiveOrganization();
  const { data, loading } = useQuery(GetTitleOrgForSetupQuery, {
    variables: { organizationId: activeOrgId! },
  });
  if (loading) {
    return <LoadingIndicator />;
  }
  if (data?.organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${data?.organization?.__typename}`);
  }
  return <OrganizationSetupLoadedWithForm viewer={data.viewer} organization={data.organization} />;
}

export default OrganizationSetup;
